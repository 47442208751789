/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:eb3c24c2-82fe-4663-b58c-69be88c68529",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_vqT5CpeWc",
    "aws_user_pools_web_client_id": "3h527lom07s6qhoabig8u0s5bt",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "getEmbedUrlApi",
            "endpoint": "https://ogjcrtmkj1.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
